<template>
  <div class="coursePreview_meeting_container">
    <!--课件PPT播放区域-->
    <div class="coursePreview_meeting_player">
      <player
        :data="data.content || []"
        ref="player"
        #default="{ citems, cindex }"
      >
        <div class="coursePreview_meeting_player_area global_full_screen">
          <MeetingItem :pageItem="citems" :index="cindex" />
        </div>
      </player>
    </div>
    <div class="coursePreview_screen_block global_meeting_padding">
      <p>建议每页讲课为60秒内，超过60秒系统将不能继续录制</p>
    </div>
    <!--课件提词内容-->
    <div class="coursePreview_meeting_content_item">
      <!--提词区-->
      <div
        class="coursePreview_meeting_title global_meeting_padding global_container_center"
      >
        解说词区
      </div>

      <!--提词字数-->
      <div class="meeting_course_preview_tips_save">
        <div class="meeting_course_preview_tips_save_countLength">
          <div class="meeting_course_preview_step_time">
            <span>字数：</span>
            <span style="color: #0100ca">{{ contentLength }}</span>
            <span>/{{ maxCount }}</span>
          </div>
        </div>
      </div>

      <div class="coursePreview_meeting_content global_meeting_padding">
        <itemScroll ref="itemScroll">
          <div style="white-space: pre-line">
            {{ data.content[courseIndex]?.default_content }}
          </div>
        </itemScroll>
      </div>
    </div>

    <!--按钮组-->
    <div class="coursePreview_meeting_button_groups">
      <!-- <div class="meeting_course_preview_step_button">
        <customButton
          class="meeting_course_preview_step"
          @click="showPopop('pptxDownload')"
          ><div>课件下载</div></customButton
        >
      </div>
      <div class="meeting_course_preview_step_button">
        <customButton
          class="meeting_course_preview_step"
          @click="showPopop('originalHelp')"
          v-if="data.pdf_url"
          ><div>原文求助</div></customButton
        >
      </div> -->
      <div class="meeting_course_preview_step_button" v-if="courseIndex">
        <customButton class="meeting_course_preview_step" @click="goToPrepPage"
          ><div>上一页</div></customButton
        >
      </div>
      <div class="meeting_course_preview_step_button">
        <customButton class="meeting_course_preview_step" @click="goToBack"
          ><div>返回</div></customButton
        >
      </div>
      <div class="meeting_course_preview_step_button">
        <!-- <customButton
          class="meeting_course_preview_step"
          @click="goToPrepPage"
          ><div>上一页</div></customButton
        > -->
        <customButton class="meeting_course_preview_step" @click="goToNextPage"
          ><div>{{ getButtonText() }}</div></customButton
        >
      </div>
    </div>

    <opacityBg @click="showPopup = false" v-show="showPopup">
      <div class="course_preview_help_block" @click.stop="">
        <p class="course_preview_help_tips">{{ getPopopText() }}</p>
        <div class="course_preview_help_email">
          <p>输入邮箱：</p>
          <el-input
            v-model="doctorInfo.email"
            class="global_text_input course_preview_help_input"
            placeholder="请输入您的邮箱"
          ></el-input>
        </div>
        <div class="course_preview_help_buttonGroup">
          <customButton
            class="course_preview_help_button"
            size="small"
            @click="commitEmail"
          >
            <div class="course_preview_help_button_info">
              <span>确认</span>
            </div>
          </customButton>
          <customButton
            class="course_preview_help_button"
            size="small"
            @click="hidePopup"
          >
            <div class="course_preview_help_button_info">
              <span>取消</span>
            </div>
          </customButton>
        </div>
      </div>
    </opacityBg>
  </div>
</template>
<script>
import { ElInput } from "element-plus";
import player from "@/components/player/player";
import customButton from "@/components/unit/customButton";
import itemScroll from "@/components/unit/itemScroll";
import opacityBg from "@/components/unit/opacityBg";
import MeetingItem from "@/components/page/meeting/meetingItem";
export default {
  name: "coursePreview",
  data: () => {
    return {
      data: {
        title: "",
        content: [],
        question: [],
      },
      doctorInfo: {
        email: "",
      },
      doSome: "",
      showPopup: false,
      operating: {
        pptxDownload: "pptxDownload",
        originalHelp: "originalHelp",
      },
      PopopText: {
        pptxDownload:
          "请输入您的邮箱，稍后我们将课件及提词通过邮件发送至您的邮箱，请注意查收",
        originalHelp:
          "本平台不提供任何原文资源，仅采用百度学术文献互助平台的C2C模式，业内同道接到您的需求后会分享至您的邮箱。",
      },
      courseIndex: 0,
      contentLength: 0,
      maxCount: 220,
    };
  },
  components: {
    player,
    opacityBg,
    itemScroll,
    customButton,
    MeetingItem,
    ElInput,
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "肿瘤抗血管靶向治疗";
    this.loadCourseware();
    this.getDoctorInfo();
  },
  methods: {
    loadCourseware() {
      let url = this.$tools.getURL(this.$urls.admin.getCourseInfo, {
        id: this.$route.query.id,
      });
      this.$axios.get(url).then((res) => {
        this.data = res.data;
        this.contentLength = this.getContentLength(
          this.data.content[this.courseIndex].default_content
        );

        this.initScroll();
      });
    },
    initScroll() {
      this.$nextTick(() => {
        this.$refs.itemScroll && this.$refs.itemScroll.initScroll();
        this.$refs.itemScroll.scrollTo(0);
      });
    },
    getDoctorInfo() {
      let doctorInfo = this.$tools.getStorage("doctorEmail");
      this.doctorInfo.email = doctorInfo ? doctorInfo.email : "";
      // let url = `${this.$urls.doctor.doctorInfo}`
      // let response = await this.$axios.get(url)
      // this.doctorInfo.email = response.data.email
    },
    getContentLength(content) {
      let item = this.$tools.dealWithText(content, this.maxCount);
      return item.count;
    },
    getPopopText() {
      return this.PopopText[this.doSome];
    },
    getButtonText() {
      if (this.isLastPage()) return "结束";
      return "下一页";
    },
    isLastPage() {
      return this.courseIndex == this.data.content.length - 1;
    },
    goToPrepPage() {
      if (this.courseIndex == 0) return;
      this.courseIndex -= 1;
      this.$refs.player.goToPage(this.courseIndex);
      this.initScroll();
    },
    goToNextPage() {
      if (this.isLastPage()) {
        this.$router.back();
        return;
      }
      this.courseIndex += 1;
      this.contentLength = this.getContentLength(
        this.data.content[this.courseIndex].default_content
      );
      this.$refs.player.goToPage(this.courseIndex);
      this.initScroll();
    },
    goToBack() {
      this.$router.back();
    },
    showPopop(doSome) {
      this.doSome = doSome;
      this.showPopup = true;
    },
    commitEmail() {
      if (!/[\w.-]+@[\w-]+\.[\w-]+/g.test(this.doctorInfo.email)) {
        this.$tips.error({ text: "请输入正确的邮箱" });
        return false;
      }
      this.$tools.setStorage("doctorEmail", { email: this.doctorInfo.email });
      if (this.doSome === "pptxDownload") {
        this.pptxDownload();
      }
      if (this.doSome === "originalHelp") {
        this.originalHelp();
      }
    },
    pptxDownload() {
      let courseware_id = this.$route.query.id;
      let url = this.$tools.getURL(this.$urls.admin.admin_share, {
        id: courseware_id,
        email: this.doctorInfo.email,
        type: 2,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.$tips.success({ text: "发送成功" });
          this.showPopup = false;
        })
        .catch((err) => {
          this.showPopup = false;
        });
    },
    originalHelp() {
      let courseware_id = this.$route.query.id;
      let url = this.$tools.getURL(this.$urls.api.sendEmail, {
        id: courseware_id,
        email: this.doctorInfo.email,
        type: 2,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.$tips.success({ text: "求助成功" });
          this.showPopup = false;
        })
        .catch((err) => {
          this.showPopup = false;
        });
    },
    hidePopup() {
      this.showPopup = false;
    },
  },
};
</script>
<style>
.coursePreview_meeting_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.coursePreview_meeting_player {
  flex: 100vw 0 0;
  width: 100%;
  position: relative;
}
.coursePreview_meeting_player_area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.coursePreview_meeting_player_area img {
  height: 100%;
  width: auto;
  max-width: 100%;
}
.coursePreview_meeting_content_item {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  background: #e4f0fe;
  /* overflow: hidden; */
  position: relative;
}
.coursePreview_meeting_button_groups {
  height: 12vw;
  width: 100%;
  background: #fff;
  padding: 0 4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .meeting_course_preview_step_button{
  display: flex;
  justify-content: center;
  justify-content: space-between;
} */
.coursePreview_screen_block {
  flex: 8.53vw 0 0;
  display: flex;
  align-items: center;
  background: #0100ca;
  color: #fff;
  font-weight: 400;
  font-size: 3.3vw;
}
.coursePreview_meeting_title {
  flex: 8.26vw 0 0;
  font-weight: 400;
  background: #7fb8ff;
}
.coursePreview_meeting_content {
  flex: 1 0 0;
  background: #e4f0fe;
  padding-top: 4vw;
  padding-bottom: 4vw;
  line-height: 6vw;
  overflow: hidden;
  position: relative;
}
.coursePreview_meeting_container .course_preview_help_block {
  background: #fff;
  width: 60%;
  padding: 4vw;
  border-radius: 1vw;
}
.coursePreview_meeting_container .course_preview_help_tips {
  line-height: 7vw;
}
.coursePreview_meeting_container .course_preview_help_email {
  margin: 8vw 0 2vw 0;
  color: #0100c8;
}
.coursePreview_meeting_container .course_preview_help_input {
  border: none;
  padding: 0;
}
.coursePreview_meeting_container .course_preview_help_buttonGroup {
  width: 100%;
  height: 8vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.coursePreview_meeting_container .course_preview_help_button {
  width: 20vw;
  height: 8vw;
  padding: 1vw;
  border-radius: 1vw;
}
.coursePreview_meeting_container .course_preview_help_button_info {
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.coursePreview_meeting_container .meeting_course_preview_tips_save {
  width: 100%;
  padding: 0 4vw;
  display: flex;
  justify-content: space-between !important;
  position: absolute;
  /* top: -8.26vw; */
  z-index: 50;
  height: 8.26vw;
}
.meeting_course_preview_tips_save_countLength {
  flex: 1 0 0;
  height: 8.26vw;
  display: flex;
  align-items: center;
}
</style>